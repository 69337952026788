import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
  },
  {
    path: "/order",
    name: "order",
    component: () => import("../views/OrderView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/service",
    name: "service",
    component: () => import("../views/ServiceView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/my",
    name: "my",
    component: () => import("../views/MyView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/tabusd",
    name: "TabUSD",
    component: () => import("../components/home/TabUSD.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/trading/:id",
    name: "trading",
    component: () => import("../components/home/TabUSD.vue"),
    meta: { requiresAuth: true },
  },
  { path: "/:pathMatch(.*)*", redirect: { path: "/" } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters.isLogin) {
    if (["login", "register"].includes(to.name)) {
      next("/");
    } else {
      next();
    }
  } else if (!store.getters.isLogin && to.meta.requiresAuth) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
