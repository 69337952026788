<template>
  <md-snackbar
    md-position="center"
    :md-active="show"
    md-persistent
    :style="css"
  >
    <slot></slot>
  </md-snackbar>
</template>

<script>
export default {
  props: {
    show: Boolean,
    css: String,
  },
};
</script>

<style lang="scss" scoped></style>
