<template>
  <div class="footer">
    <md-bottom-bar
      md-theme="bottom-bar-yellow"
      md-sync-route
      style="background-color: #1a1a1a"
    >
      <md-bottom-bar-item
        to="/"
        exact
        :md-label="$t('footer.label1')"
        :md-icon="require('@/assets/icons/quote.svg')"
      ></md-bottom-bar-item>
      <md-bottom-bar-item
        to="/order"
        :md-label="$t('footer.label2')"
        :md-icon="require('@/assets/icons/invest.svg')"
      ></md-bottom-bar-item>
      <md-bottom-bar-item
        to="/service"
        :md-label="$t('footer.label3')"
        :md-icon="require('@/assets/icons/service.svg')"
      ></md-bottom-bar-item>
      <md-bottom-bar-item
        to="/my"
        :md-label="$t('footer.label4')"
        :md-icon="require('@/assets/icons/my.svg')"
      ></md-bottom-bar-item>
    </md-bottom-bar>
  </div>
</template>

<script>
export default {
  name: "FooterApp",
};
</script>

<style lang="scss">
@import "vue-material/dist/theme/engine";

@include md-register-theme(
  "bottom-bar-yellow",
  (
    primary: md-get-palette-color(yellow, A700),
    theme: dark,
  )
);

@import "vue-material/dist/theme/all";
</style>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: #1a1a1a;
}

.md-bottom-bar.md-type-fixed .md-bottom-bar-item {
  max-width: none;
}
</style>
