<template>
  <div>
    <div class="lang" @click="showDialog = true" v-if="!flag">
      <span>{{ $t("langSelect.lang") }}</span>
      <svg height="10" width="10" viewBox="0 0 512 512">
        <path
          fill="#fff"
          d="M190.06 414l163.12-139.78a24 24 0 000-36.44L190.06 98c-15.57-13.34-39.62-2.28-39.62 18.22v279.6c0 20.5 24.05 31.56 39.62 18.18z"
        ></path>
      </svg>
    </div>
    <div class="lang-flag" @click="showDialog = true" v-if="flag">
      <img :src="flagIcon[$i18n.locale]" alt="Flag" />
      <span>{{ $t("langSelect.lang") }}</span>
    </div>
    <md-dialog
      :md-active.sync="showDialog"
      md-theme="dialog-dark"
      :md-fullscreen="false"
    >
      <div class="dialog">
        <label>
          <div class="item">
            <span>English</span>
            <md-radio
              v-model="lang"
              value="en"
              class="md-primary"
              md-theme="dialog-dark"
            ></md-radio>
          </div>
        </label>
        <label>
          <div class="item">
            <span>Tiếng Việt</span>
            <md-radio
              v-model="lang"
              value="vi"
              class="md-primary"
              md-theme="dialog-dark"
            ></md-radio>
          </div>
        </label>
      </div>
      <md-button class="md-raised md-primary" @click="setLang()">
        {{ $t("langSelect.btnQut") }}
      </md-button>
      <md-button class="md-raised btn-cancel" @click="showDialog = false">
        {{ $t("langSelect.btnCancel") }}
      </md-button>
    </md-dialog>
    <SnackBar :show="showSnackBar" css="color: #fff;background-color: #4fc14f;">
      {{ $t("langSelect.noteSnackBar") }}
    </SnackBar>
  </div>
</template>

<script>
import SnackBar from "@/components/common/SnackBar.vue";

export default {
  props: {
    flag: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SnackBar,
  },
  data() {
    return {
      showDialog: false,
      showSnackBar: false,
      lang: "vi",
      flagIcon: {
        vi: require("@/assets/images/flag/vi.svg"),
        en: require("@/assets/images/flag/en.svg"),
      },
    };
  },
  methods: {
    getLang() {
      const locale = localStorage.getItem("locale");
      const lang = locale ? locale : "vi";
      return lang;
    },
    setLang() {
      localStorage.setItem("locale", this.lang);
      this.$i18n.locale = this.lang;
      this.showDialog = false;
      this.showSnackBar = true;
      setTimeout(() => {
        this.showSnackBar = false;
      }, 2000);
    },
  },
  mounted() {
    this.lang = this.getLang();
  },
};
</script>

<style lang="scss">
@import "vue-material/dist/theme/engine";

@include md-register-theme(
  "dialog-dark",
  (
    primary: #ebcc7f,
    theme: dark,
  )
);

@import "vue-material/dist/theme/all";
</style>

<style lang="scss" scoped>
.lang {
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}
.lang-flag {
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 30px;
    height: 20px;
  }
  span {
    font-size: 12px;
    color: #fff;
    margin-left: 5px;
  }
}
.dialog {
  padding: 13px 16px;
  overflow-y: auto;
  max-height: 65vh;
  @media only screen and (min-device-width: 736px) {
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #424242;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 6px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  label {
    &:last-child {
      .item {
        border-bottom: none;
      }
    }
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px solid #d8d8d84f;
    margin-bottom: 5px;
  }
}
.btn-cancel {
  margin-left: 8px;
}
</style>
