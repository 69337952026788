<template>
  <md-dialog :md-active="show" md-theme="dialog-dark" :md-fullscreen="false">
    <div class="dialog">
      <div class="title">{{ $t("withdrawModal.title") }}</div>
      <input
        type="number"
        :placeholder="$t('withdrawModal.plh1')"
        v-model="amount"
      />
      <input
        type="password"
        :placeholder="$t('withdrawModal.plh2')"
        style="margin-top: 20px"
        v-model="password"
      />
    </div>
    <md-button class="md-raised md-primary" @click="onSubmitWithdrawal">
      {{ $t("withdrawModal.btnOk") }}
    </md-button>
    <md-button class="md-raised btn-cancel" @click="$emit('close')">
      {{ $t("withdrawModal.btnCancel") }}
    </md-button>
  </md-dialog>
</template>

<script>
import API from "@/api";
import request from "@/request";
import { mapGetters } from "vuex";

export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      amount: 0,
      password: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    async onSubmitWithdrawal() {
      try {
        const data = {
          amount: this.amount,
          password: this.password,
        };
        const withdraw = await request.post(API.WITHDRAW, data);
        if (withdraw.data.success === 1) {
          this.$toast.success(this.$t("success"));

          this.$store.commit("UPDATE_USER_INFO", {
            money: this.userInfo.money - this.amount,
          });
          
          this.$socket.getSocket.emit("user", {
            type: "notification",
            data: {
              message: "withdraw",
            },
          });
        }
      } catch (err) {
        const message = this.$response(err.data.error?.message);
        return this.$toast.error(message || "error");
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "vue-material/dist/theme/engine";

@include md-register-theme(
  "dialog-dark",
  (
    primary: #ebcc7f,
    theme: dark,
  )
);

@import "vue-material/dist/theme/all";
</style>

<style lang="scss" scoped>
.dialog {
  padding: 13px 10px;
  overflow-y: auto;
  max-height: 65vh;
  @media only screen and (min-device-width: 736px) {
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #424242;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 6px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .title {
    font-size: 16px;
    border-bottom: 1px solid rgb(82, 82, 82);
    padding-bottom: 8px;
    text-align: center;
  }
  input {
    border: none;
    outline: none;
    margin-top: 25px;
    font-size: 16px;
    background-color: transparent;
    color: #fff;
    width: 100%;
  }
}
.btn-cancel {
  margin-left: 8px;
  margin-bottom: 16px;
}
</style>
