<template>
  <div class="business-infomation" @click="showModal = true">
    <div class="left">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 512 512"
      >
        <path
          fill="none"
          stroke="currentColor"
          stroke-linejoin="round"
          stroke-width="32"
          d="M160 336V48l32 16 32-16 31.94 16 32.37-16L320 64l31.79-16 31.93 16L416 48l32.01 16L480 48v224"
        />
        <path
          d="M480 272v112a80 80 0 01-80 80h0a80 80 0 01-80-80v-48H48a15.86 15.86 0 00-16 16c0 64 6.74 112 80 112h288"
          fill="none"
          stroke="currentColor"
          stroke-linejoin="round"
          stroke-width="32"
        />
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="32"
          d="M224 144h192M288 224h128"
        />
      </svg>
      <span>{{ $t("businessInformation.title") }}</span>
    </div>
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 512 512"
      >
        <path
          d="M190.06 414l163.12-139.78a24 24 0 000-36.44L190.06 98c-15.57-13.34-39.62-2.28-39.62 18.22v279.6c0 20.5 24.05 31.56 39.62 18.18z"
        ></path>
      </svg>
    </div>
    <Transition name="slide">
      <div class="modal" v-if="showModal">
        <md-toolbar class="custom-toolbar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            viewBox="0 0 512 512"
            @click.stop="showModal = false"
          >
            <path
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="48"
              d="M244 400L100 256l144-144M120 256h292"
            />
          </svg>
          <h3 class="md-title">{{ $t("businessInformation.title") }}</h3>
        </md-toolbar>
        <div class="content">
          <h3>{{ $t("businessInformation.subTitle1") }}</h3>
          <p>
            {{ $t("businessInformation.content1") }}
          </p>
          <h4>{{ $t("businessInformation.subTitle2") }}</h4>
          <p>
            {{ $t("businessInformation.content2") }}
          </p>
          <h4>{{ $t("businessInformation.subTitle3") }}</h4>
          <p>
            {{ $t("businessInformation.content3") }}
          </p>
          <h4>{{ $t("businessInformation.subTitle4") }}</h4>
          <p>
            {{ $t("businessInformation.content4") }}
          </p>
          <h4>{{ $t("businessInformation.subTitle5") }}</h4>
          <p>
            {{ $t("businessInformation.content5") }}
          </p>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.business-infomation {
  background-color: #efd277;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  .left {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      margin-left: 6px;
    }
  }

  .modal {
    position: fixed;
    min-height: 100vh;
    background-color: #141414;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    overflow-y: auto;
    .custom-toolbar {
      background-color: #1a1a1a;
      h3 {
        color: #fff;
      }
    }
    .content {
      padding: 25px;
      color: #fff;
      h4 {
        font-size: 20px;
        font-weight: 500;
        margin: 16px 0 10px;
      }
    }
  }
}

.slide-enter-active {
  animation: slide-in-bottom 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-leave-active {
  animation: slide-out-bottom 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@keyframes slide-in-bottom {
  0% {
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-out-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(1000px);
    opacity: 0;
  }
}
</style>
